import { Controller } from "@hotwired/stimulus";
import { appendQueryParamToLink } from "../appQueryParamToLink";

export default class extends Controller {
  static values = {
    wines: Array,
  };
  connect() {
    $("#name").autocomplete({
      source: this.winesValue,
    });
  }
}
